.dashboard-layout {
    display: flex;
    width: 100%;
}
.dashboard-layout .ant-layout-sider-zero-width-trigger {
    top: 10px !important;
}
.dashboard-content {
    padding: 10px;
    width: 100%;
}
.dashboard-layout, .dashboard-sidebar {
    min-height: 100vh;
}
/* .nav-active {
    color: #ff7f47 !important;
} */