@import "~antd/dist/antd.css";

#root {
  position: relative;
}
body {
  font-family: "Poppins", sans-serif;
}

footer {
  padding: 15px;
  color: white;
  background-color: #757070;
  border-top: 1px solid white;
  text-align: center;
}

.ant-pagination-item-active a,
.ant-pagination-item:hover a,
.ant-pagination-item-active > * {
  color: #ff7f47 !important;
}

.ant-radio-inner::after,
::selection {
  background: #ff7f47;
}

.ant-pagination-item-active,
.ant-table-pagination > *:hover,
.ant-input:focus,
.ant-input-focused,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input:hover,
.ant-radio-checked::after,
.ant-radio:hover,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #ff7f47 !important;
}

.ant-select-selector:hover,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: 0 0 0 2px rgba(255, 127, 71, 0.31) !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-btn-default:hover,
.ant-btn-default:focus {
  border-color: #ff7f47 !important;
  color: #ff7f47 !important;
}

.ant-btn-primary {
  border-color: #ff7f47 !important;
  background-color: #ff7f47 !important;
}

.ant-btn:hover,
.ant-btn:focus {
  opacity: 0.9;
}

.ant-input:focus,
.ant-input-focused,
.ant-input:hover {
  box-shadow: none;
}

.ant-modal-header {
  background-color: #ff7f47 !important;
}

.ant-modal-title,
.ant-modal-close-x {
  color: white;
  font-weight: bold;
}

.ant-modal-body {
  background: #eee;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: #ff7f47;
  color: white;
  opacity: 0.9;
}

.ant-btn-ghost.active,
.ant-btn-ghost:focus,
.ant-btn-ghost:hover {
  border-color: #ff7f47;
  color: #ff7f47;
}

.containerWrapper {
  margin: 20px auto;
  width: 70%;
}

.ant-form-item {
  margin-bottom: 10px;
}

@media screen and (max-width: 700px) {
  .containerWrapper {
    width: 90%;
  }
  .reg_titel {
    font-size: 1rem !important;
  }
  .registration-card{
    margin-top: 5px !important;
  }
}

.responsiveTable {
  overflow-x: scroll;
}

.ant-table tbody > tr:nth-child(2n + 1) > td {
  background-color: rgba(250, 250, 250, 0.8);
  /* background-color: #f0f0f0; */
}

.main-title {
  color: #ff7f47;
  font-weight: bold;
}

.registration-card{
  margin-top: 40px;
}
