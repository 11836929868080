.App {
  background: #eee;
}
.header {
  /* height: 130px; */
  background-color: white;
}

.header .content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
}

.header .ant-row {
  height: 100%;
}
.header .ant-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .menu {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.header .menu > div {
  margin-left: 20px;
  margin-right: 20px;
}

.header .menu a {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #474747;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 1px solid;
  border-color: transparent;
}

.header .menu a:hover,
.ant-dropdown-menu-title-content:hover {
  color: #ff5a12;
  border-color: #ff5a12;
}

.call-us-btn {
  font-size: 12px;
  padding: 18px 10px;
  font-weight: 600;
  height: unset;
  min-width: 160px;
  position: relative;
  text-align: center;
  z-index: 1;
  transition: 0.3s;
  line-height: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 40px;
  letter-spacing: 1px;
  margin-left: 5px;
}

.call-us-btn2 {
  font-size: 12px;
  padding: 18px 10px;
  font-weight: 600;
  height: unset;
  min-width: 160px;
  position: relative;
  text-align: center;
  z-index: 1;
  transition: 0.3s;
  line-height: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 40px;
  letter-spacing: 1px;
  margin-right: auto;
  background-color: rgba(8, 8, 8, 0.925);
  color: white;
}

.header .logo {
  width: 250px;
}

.body {
  background-color: #eee;
  min-height: 100vh;
}

@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    justify-content: center;
    height: unset;
    padding-bottom: 50px;
  }
}
